import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
const _import = require('./_import_' + process.env.NODE_ENV)
const _importCom = require('./_importCom_' + process.env.NODE_ENV)
Vue.use(VueRouter)

const routes = [{
  path: "/", //首页
  name: "index",
  component: _import('index'),
  meta: {
    keepAlive: true,
    isBack: false,
  }
},
{
  path: "/search", //查询页
  name: "search",
  component: _import('search'),
  meta: {
    keepAlive: true,
    isBack: false,
  }
},
{
  path: "/classification", //
  name: "classification",
  component: _import('classification'),
  meta: {
    keepAlive: true,
    isBack: false,
  }
},
{
  path: "/consultingService", //资讯
  name: "consultingService",
  component: _import('consultingService'),
  meta: {
    keepAlive: true,
    isBack: false,
  }
},
{
  path: "/browseRecords", //浏览记录
  name: "browseRecords",
  component: _import('browseRecords'),
  meta: {
    keepAlive: true,
    isBack: false,
  }
},
{
  path: "/course", //教程
  name: "course",
  component: _import('course'),
  meta: {
    keepAlive: true,
    isBack: false,
  }
},
{
  path: "/videoPlay", //视频播放
  name: "videoPlay",
  component: _import('videoPlay'),
  meta: {
    keepAlive: false,
    isBack: false,

  }
},
{
  path: "/news", //消息
  name: "news",
  component: _import('news'),
  meta: {
    keepAlive: true,
    isBack: false,
  }

},
{
  path: "/About", //消息
  name: "About",
  component: _import('About'),
  meta: {
    keepAlive: true,
    isBack: false,
  }

},
{
  path: "/newest", //最新
  name: "newest",
  component: _import('newest'),
  meta: {
    keepAlive: true,
    isBack: false,
  }

},
{
  path: "/hot", //热门
  name: "hot",
  component: _import('hot'),
  meta: {
    keepAlive: true,
    isBack: false,
  }

},
{
  path: "/secondary", //二级页
  name: "secondary",
  component: _import('secondary'),
  redirect: '/secondary/videoStatistics',
  children: [{
      path: 'videoStatistics', //视频统计
      name: 'videoStatistics',
      component: _importCom('videoStatistics'),
      meta: {
        keepAlive: true,
        isBack: false,
      }
    },
    {
      path: 'creativeConvention', //创作公约
      name: 'creativeConvention',
      component: _importCom('creativeConvention'),
      meta: {
        keepAlive: true,
        isBack: false,
      }
    },
    {
      path: 'videoAdministration', // 视频管理
      name: 'videoAdministration',
      component: _importCom('videoAdministration'),
      meta: {
        keepAlive: true,
        isBack: false,
      }
    },
    {
      path: 'releaseVideo', // 发布视频
      name: 'releaseVideo',
      component: _importCom('releaseVideo'),
      meta: {
        keepAlive: true,
        isBack: false,
      }
    }
  ]
}
];
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
