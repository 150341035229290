require('./array.js')
var AvatarServer="http://avatar.ourgame.com/gif/";
	
var NowSex="M";

//var showArray=new Array();
var defShowArray=new Array();
var GameshowFloder=new Array();
var GameshowItemNo=new Array();

function getCookieVal (offset) { 
    var endstr = document.cookie.indexOf (";", offset); 
    if (endstr == -1) 
    endstr = document.cookie.length; 
    return unescape(document.cookie.substring(offset, endstr)); 
} 

function GetCookie (name) { 
    var arg = name + "="; 
    var alen = arg.length; 
    var clen = document.cookie.length; 
    var i = 0; 
    while (i < clen) { 
        var j = i + alen; 
        if (document.cookie.substring(i, j) == arg) 
            return getCookieVal (j); 
        i = document.cookie.indexOf(" ", i) + 1; 
        if (i == 0) break; 
    } 
    return null; 
}

//去掉空格
String.prototype.Trim = function()
{
//		return this.replace(/(^\s*)|(\s*$)/g, "");
}

String.prototype.LTrim = function()
{
    return this.replace(/(^\s*)/g, "");
}

String.prototype.RTrim = function()
{
    return this.replace(/(\s*$)/g, "");
}

var my_array = new Array(new Array(),new Array(),new Array(),new Array())

//显示某个用户的联众秀形像（层的名字，用户性别，得到的联众绣字串）
function BeautyContest(strValue,DivName,Sex,width1,height1){
    var boydefShow = "0000240680000000000000000000000000000000000000000000000000000000";
var grildefShow ="0000350790000000000000000000000000000000000000000000000000000000";
var ExcludeArray=new Array();
ExcludeArray[0]= "0000000000000000000000000000000000000000000000000000000000000000";
ExcludeArray[1]= "0000000000000000000000000000000000000000000000000000000000000001";
ExcludeArray[2]= "0000000000000000000000000000000000000000000000000000000000000010";
ExcludeArray[3]= "0000000000000000000000000000000000000000000000000000000000000100";
ExcludeArray[4]= "0000000000000000000000000000000000000000000000010000000000001000";
ExcludeArray[5]= "0000000000000000000000000000000000000000000000010000000000010000";
ExcludeArray[6]= "0000000000000000000000000000000000000000000000011001000000100000";
ExcludeArray[7]= "0000000000000000000000000000000000000000000000011000101001000000";
ExcludeArray[8]= "0000000000000000000000000000000000000000000000011000100110000000";
ExcludeArray[9]= "0000000000000000000000000000000000000000000000011000100110000000";
ExcludeArray[10]="0000000000000000000000000000000000000000000000011000101001000000";
ExcludeArray[11]="0000000000000000000000000000000000000000000000010000010000000000";
ExcludeArray[12]="0000000000000000000000000000000000000000000000011000101111000000";
ExcludeArray[13]="0000000000000000000000000000000000000000000000011001000000100000";
ExcludeArray[14]="0000000000000000000000000000000000000000000000011010000000000000";
ExcludeArray[15]="0000000000000000000000000000000000000000000000011100000000000000";
ExcludeArray[16]="0000000000000000000000000000000000000000001111111111101111100000";
ExcludeArray[17]="0000000000000000000000000000000000000000001111111111111111111000";
ExcludeArray[18]="0000000000000000000000000000000000000000000000111000000000000000";
ExcludeArray[19]="0000000000000000000000000000000000000000000001011000000000000000";
ExcludeArray[20]="0000000000000000000000000000000000000000000010011000000000000000";
ExcludeArray[21]="0000000000000000000000000000000000000000000100011000000000000000";
ExcludeArray[22]="0000000000000000000000000000000000000000001000011000000000000000";
ExcludeArray[23]="0000000000000000000000000000000000000000010000000000000000000000";
ExcludeArray[24]="0000000000000000000000000000000000000000100000000000000000000000";
ExcludeArray[25]="0000000000000000000000000000000000000001000000000000000000000000";
ExcludeArray[26]="0000000000000000000000000000000000000010000000000000000000000000";
ExcludeArray[27]="0000000000000000000000000000000000000100000000000000000000000000";
ExcludeArray[28]="0000000000000000000000000000000000001000000000000000000000000000";
ExcludeArray[29]="0000000000000000000000000000000000010000000000000000000000000000";
ExcludeArray[30]="0000000000000000000000000000000000100000000000000000000000000000";
ExcludeArray[31]="0000000000000000000000000000000000000000000000000000000000000000";
ExcludeArray[32]="0000000000000000000000000000000000000000000000000000000000000000";
ExcludeArray[33]="0000000000000000010000001100000100000000000000000000000000000000";
ExcludeArray[34]="0000000000000000010000001100001000000000000000000000000000000000";
ExcludeArray[35]="0000000000000000010000001100010000000000000000000000000000000000";
ExcludeArray[36]="0000000000000000010000001100100000000000000000000000000000000000";
ExcludeArray[37]="0000000000000000010000001101000000000000000000000000000000000000";
ExcludeArray[38]="0000000000000000010000001110000000000000000000000000000000000000";
ExcludeArray[39]="0000000000000000000000000000000000000000000000000000000000000000";
ExcludeArray[40]="0000000000000000000000000000000000000000000000000000000000000000";
ExcludeArray[41]="0000000000000000010000010000000000000000000000000000000000000000";
ExcludeArray[42]="0000000000000000000000100000000000000000000000000000000000000000";
    //s = strValue.split(",");
        if (strValue == "" || strValue == "0")
            strValue = "1, 17770/17";
        s=strValue;
        if(s.indexOf("%") != -1)
            s = decodeURI(strValue);
            
        s = s.replace(/\+/g, ' ');
        s = s.split(",");
        var my_array = new Array();
        var strlengh=s.length;
        for (i=0;i<strlengh;i++){
            my_array[i]=s[i].split("/");
        }
        var mylengh=my_array.length;
        let gameshowstr = "";
        let stradd = "";
        //alert(my_array[1][1]);
        //*********************************
        for (let j=0;j<64;j++)
        {
            for (let k=0;k<mylengh;k++)
            {
            if(j==my_array[k][1])
                {
                stradd=my_array[k][0].replace(/^\s+|\s+$/g, "");
                break;
                }
            else
                {
                stradd="0";
                }
            }
            if(j!=0)
            {
            gameshowstr=gameshowstr+","+stradd;
            }
            else
            {
            gameshowstr=gameshowstr+stradd;
            }
        }
        let GameshowArray=gameshowstr.split(',');
        
        var mark1,mark2,mark3,mark4,mark5,mark6;
        mark1=0;
        mark2=0;
        mark3=0;
        mark4=0;
        mark5=0;
        mark6=0;
        for(var i=0;i<64;i++){
            if(GameshowArray[i] != '0'){
                var Exclude;
                let Excludey=ExcludeArray[i];
                let ExcludeArray2=Excludey.split('');
                //是否显示脸部
                if(mark1!=1){
                    if(GameshowArray[4]!='0' && GameshowArray[4]!='29783' && GameshowArray[4]!='29783'){
                        mark1=1;
                    }else{
                        if(ExcludeArray2[60]=='1' && i!=4){
                            GameshowArray[4]='0';
                            mark1=1;
                        }else{
                            if(Sex=='M'){
                                GameshowArray[4]='29783';
                            }else{
                                GameshowArray[4]='29783';
                            }	
                        }
                    }
                }
                //是否显示表情
                if(mark2!=1){
                    if(GameshowArray[5]!='0' && GameshowArray[5]!='29781' && GameshowArray[5]!='29780'){
                        mark2=1;
                    }else{
                        if(ExcludeArray2[59]=='1' && i!=5){
                            GameshowArray[5]='0';
                            mark2=1;
                        }else{
                            if(Sex=='M'){
                                GameshowArray[5]='29781';
                            }else{
                                GameshowArray[5]='29780';
                            }	
                        }
                    }
                }
                //是否显示上身
                if(mark3!=1){
                    if(GameshowArray[10]!='0' && GameshowArray[10]!='29793' && GameshowArray[10]!='29792'){
                        mark3=1;
                    }else{
                        if(ExcludeArray2[57]=='1' && i!=10){
                            GameshowArray[10]='0';
                            mark3=1;
                        }else{
                            if(Sex=='M'){
                                GameshowArray[10]='29793';
                                    }else{
                                GameshowArray[10]='29792';
                            }	
                        }
                    }
                }
                //是否显示下身
                if(mark4!=1){
                    if(GameshowArray[8]!='0' && GameshowArray[8]!='29785' && GameshowArray[8]!='29784'){
                        mark4=1;
                    }else{
                        if(ExcludeArray2[56]=='1' && i!=8){
                            GameshowArray[8]='0';
                            mark4=1;
                        }else{
                            if(Sex=='M'){
                                GameshowArray[8]='29785';
                            }else{
                                GameshowArray[8]='29784';
                            }	
                        }
                    }
                }
                
                //是否显示头发
                if(mark5!=1){
                    if((GameshowArray[13]!='0' && GameshowArray[13]!='29789' && GameshowArray[13]!='29788') || GameshowArray[6]!='0'){
                        mark5=1;
                    }else{
                        if(ExcludeArray2[53]=='1' && i!=13){
                            GameshowArray[13]='0';
                            mark5=1;
                        }else if(GameshowArray[13]=='0'){
                            mark5=1;
                        }else{
                            if(Sex=='M'){
                                GameshowArray[13]='29789';
                            }else{
                                GameshowArray[13]='29788';
                            }	
                        }
                    }
                }
                //是否显示背景
                if(mark6!=1){
                    if(GameshowArray[1]!='0' && GameshowArray[1]!='1'){
                        mark6=1;
                    }else{
                        if(ExcludeArray2[61]=='1' && i!=1){
                            GameshowArray[1]='0';
                            mark6=1;
                        }else{
                            if(Sex=='M'){
                                GameshowArray[1]='1';
                            }else{
                                GameshowArray[1]='1';
                            }	
                        }
                    }
                }
            }
        }
        //控制图片的显示
        var left1,top1;
        if(width1 == '150' && height1 == '190'){
            left1 = 0;
            top1 = 0;
        }else{
            left1 = ((150 - width1) / 2) * -1;
            top1 = ((190 - height1) / 2 - 10) * -1;
        }
        var s="";
        for (var i=0; i<64; i++)
        {
            if(GameshowArray[i] != '0')
            {
               let strUrl= "<IMG id='s" + i + "' src='" + AvatarServer + i + "/" + GameshowArray[i] + ".gif' style='padding:0;position:absolute;top:" + top1 + "px;left:" + left1 + "px;width:136px;height:190px;z-index:" + i + ";'>";
                
                s = s + strUrl;
            }
        }
        s = s + "<IMG src='http://avatar.ourgame.com/gif/1/blank.gif' style='padding:0;position:absolute;top:0;left:0;width:136px;height:190px;z-index:64;'>";
        
        let gameshowObj = eval(DivName);
        gameshowObj.innerHTML = s;
}
export default{
    BeautyContest
}