import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'assets/icon/iconfont.css'
import 'element-ui/lib/theme-chalk/index.css';
import * as Globalcookie from './untils/cookie.js';
import * as GlobalGameshow from './untils/gameshow.js';
import {handleTime,timestampToTime} from '@/untils/common';
import "@/less/base.less"
import 'babel-polyfill'
// import './untils/aliplayercomponents-1.0.8.min.js'
// import './lib/aliyun-upload-sdk/lib/es6-promise.min.js'
// import './lib/aliyun-upload-sdk/lib/aliyun-oss-sdk-6.13.0.min.js'
// import './lib/aliyun-upload-sdk/aliyun-upload-sdk-1.5.2.min.js'
// import 'swiper/swiper-bundle.css'
// import VueAwesomeSwiper from 'vue-awesome-swiper'
Vue.prototype.BeautyContest = GlobalGameshow.default.BeautyContest
Vue.prototype.handleTime = handleTime
Vue.prototype.timestampToTime = timestampToTime
Vue.config.productionTip = false
// Vue.use(VueAwesomeSwiper, /* { default options with global component } */)
Vue.use(ElementUI);
router.beforeEach((to, from, next) => {
  // console.log('------->' + JSON.stringify(to))
  // console.log('------->' + JSON.stringify(from))
  console.log(from.path)
  console.log(to.path)
  if (from.path != '/videoPlay' && to.path == '/videoPlay') {
    console.log('我执行了')

    // to.meta.isBack = true;

    // debugger
    // localStorage.setItem('videorefeshInfo', true)
    // this.$router.go(0)
    // this.$router.push(to.path)
    // this.$forceUpdate()
  }
  next()
})
if (Globalcookie.default.GetCookie("s1") != null) {
  store.commit('GET_COOKIE', Globalcookie.default.GetCookie("s2"))
}
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
