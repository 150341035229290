/**
 *  判断传入的s是否为空
 */
function isempty(s) {
    if (s == null) {
        return true;
    }
    if (typeof s == 'string') {
        if (s == undefined || s == '' || s == 'null' || s.trim() == '') {
            return true;
        } else {
            return false;
        }
    } else if (typeof s == 'object') {
        if (s.length == 0) {
            return true;
        } else {
            return false;
        }
    } else {
        return false;
    }
}
/**
 * 获取当前url链接参数 链接地址的参数键值对
 */
export function getHrefParams() {
    var href = location.href;
    // if (href.indexOf('#') != -1) {
    //     href = href.substring(0, href.lastIndexOf('#'));
    //     console.log(href)
    //     debugger
    // }
    href = decodeURI(href);
    var params = {};

    try {
        if (href.indexOf("?")) {
            href = href.split("?")[1].split("&");
            for (var i = 0; i < href.length; i++) {

                var e = href[i];
                var k = e.split("=")[0];
                var v = e.split("=")[1];
                if (isempty(params[k])) {
                    params[k] = v;
                } else {
                    var p = params[k];
                    if (!isempty(p.push)) {
                        p.push(v);
                    } else {
                        var arr = [];
                        arr.push(p);
                        arr.push(v);
                        params[k] = arr;
                    }
                }
            }
        }
    } catch (e) {
        return {};
    }

    return params;
}


// 时间转换为分秒
export function handleTime(time) {
    return handle_number(Math.floor(time)) + ':' + handle_number(parseInt(time % 60))
}

function handle_number(time) {
    return time < 10 ? '0' + time : time
}

// 时间格式
export function timestampToTime(timestamp) {
    var date = new Date(timestamp); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
    var Y = date.getFullYear() + '-';
    var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
    var D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' ';
    var h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
    var m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':';
    var s = (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds());

    var strDate = Y + M + D + h + m + s;
    return strDate;
}