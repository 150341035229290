<template>
    <div style="padding-top:19px;">
    <div style="text-align:center; font-size:12px; color:#4D4D4D; line-height:19px; clear:both;"><span
            style="font-size:12px;color:#d90208;padding-bottom:3px">抵制不良游戏 拒绝盗版游戏 注意自我保护 谨防受骗上当 适度游戏益脑 沉迷游戏伤身 合理安排时间
            享受健康生活 ——《<a href="http://www.ourgame.com/about/post.html" target="_blank"
                style="color:#d90208;">健康游戏忠告</a>》</span>
        <div style="height:2px; background-color:#d90208; margin:10px 0; "></div><span
            style="font-size:12px;color:#E1E1E1;"><a href="http://www.ourgame.com/about/glabout.html" target="_blank">
                <font color="#4D4D4D" style="text-decoration: none;">联众简介</font>
            </a> | <a href="http://www.ourgame.com/srvcenter/agreement/agreement.html" target="_blank">
                <font color="#4D4D4D" style="text-decoration: none;">服务条款</font>
            </a> | <a href="http://www.ourgame.com/about/contact.html" target="_blank">
                <font color="#4D4D4D" style="text-decoration: none;">联系我们</font>
            </a></span>
        <div style="height:10px; border-bottom: 1px dotted #bfbfbf; color: #333333; "></div>
        <div class="copy">
            <div style="margin: 0 10px 20px 10px;">
                游戏中使用到的游戏币、门票等均为游戏道具，不具有任何财产性功能，只限用户本人在游戏中使用。本公司对于用户所拥有的游戏币、门票不提供任何形式的官方回购、直接或变相<br>兑换现金或实物等服务及相关功能。本公司严禁用户之间在游戏中及线下进行任何相互叫卖、转让游戏币等行为，如一经查出则永久封杀，账户清零。
            </div>通用网址：联众世界<br><a href="http://www.ourgame.com/about/netxkz.html" target="_blank">
                <font color="#4D4D4D" style="text-decoration: none;">网络文化经营许可证&nbsp;京网文[2018]5376-415号</font>
            </a> | <a href="http://www.ourgame.com/about/icp.html" target="_blank">
                <font color="#4D4D4D" style="text-decoration: none;">增值电信业务经营许可证 京B2-20181260号</font>
            </a><br>中国计算机信息网络国际联网单位编号：11010502017837 | 网站备案号: 京ICP备09074391号-2 |
            出版服务许可证：（署）网出证（京）字第268号<br>批准文号：科技与数字[2013]20号 | ISBN号：ISBN 978-7-89989-583-2 | 公安机关备案号：11010502017838<br>
            联众游戏版权所有? Ourgame. All Rights Reserved | 北京联通提供网络带宽<br>
        </div>
    </div>
</div>
</template>
<script>
  export default {
    name: "footer",
    data() {
      return {
    
      }
    },
    methods: {
 
    }
  }
</script>
<style lang="less">

</style>