<template>
<div>
   <router-view :key="$route.fullPath" />
   <Tfooter></Tfooter>
</div>
 


</template>
<script>
  import Tfooter from '@/components/footer.vue';
  export default {
    name: 'App',
    components: {
      Tfooter
    },
  }
</script>
<style lang="less">
  * {
    margin: 0px;
    padding: 0px;
    font-family: 'STHeiti';
  }

  li {
    list-style: none;
  }

  a {
    text-decoration: none;
  }

  button {
    cursor: pointer;
  }

  em {
    font-style: normal;
  }

  input,
  button,
  textarea {
    outline: none;
    border: 0px;
  }

  .mauto {
    width: 1200px;
    margin: 0px auto;
  }

  .el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: #ff8a00 !important;
  }

  .el-pagination.is-background .btn-next,
  .el-pagination.is-background .btn-prev,
  .el-pagination.is-background .el-pager li {
    background: white !important;
    border-radius: 4px;
    border: solid 1px #dddddd;
  }

  // .el-pagination.is-background .btn-next, .el-pagination.is-background .btn-prev{
  //   display:none;
  // }
  .el-pagination.is-background .el-pager li:not(.disabled):hover {
    color: #606266 !important;
  }

  .subnavLeft {
    width: 210px;
    height: 937px;
    padding-top: 30px;
    background: white;
    position: fixed;
    top: 65px;
    left: 0px;
    z-index: 9;

    .releaseBtn {
      width: 150px;
      height: 46px;
      cursor: pointer;
      background-color: #ff8a00;
      border-radius: 23px;
      margin: 5px auto;
      color: white;
      font-size: 16px;
      border: 0px;
      display: block;

      span {
        padding-right: 6px;
        font-size: 18px;
      }
    }

    li {
      width: 100%;
      width: 209px;
      height: 47px;
      cursor: pointer;
      text-align: center;
      line-height: 47px;

      a {
        width: 100%;
        width: 209px;
        height: 47px;
        text-align: center;
        line-height: 47px;
        display: block;
        color: #6c6c6c;
      }

      span {
        color: #d8d8d8;
        padding-right: 9px;
        font-size: 18px;
      }
    }

    .activeSubnac {}
  }

  .el-tabs__item.is-active {
    color: #ff8a00 !important;
  }

  .el-tabs__active-bar {
    background: #ff8a00 !important;
  }

  .el-tabs__item:hover {
    color: #ff8a00 !important;
  }

  .router-link-active {
    background-color: #fafafa !important;
  }

  .modal {
    width: 296px;
    height: 185px;
    background-color: #ffffff;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.21);
    border-radius: 4px;
    position: fixed;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    margin: auto;
    z-index: 9999;

    img {
      width: 8px;
      height: 8px;
      float: right;
      padding: 7px 7px 0px 0px;
      cursor: pointer;
    }

    p {
      // padding-top:67px;
      text-align: center;
      font-size: 17px;
      line-height: 21px;
      padding: 67px 8px 0px 8px;
      height: 60px;
    }

    button {
      font-size: 12px;
      color: #ffffff;
      padding: 7px 16px;
      background-color: #ff8a00;
      border-radius: 13px;
      margin-left: 85px;

    }

    .blanka {
      margin-left: 81px;

      a {
        color: white;
      }
    }

    .lznoenough {
      padding-top: 60px;
    }

    .blanka+button {
      margin-left: 10px;
    }

    button+button {
      background-color: #00a1d6;
      margin-left: 16px;
    }
  }
.info{
   width:100%;
   overflow:hidden;

   .info_box{
     float:left;
     margin:20px 0px 0px 20px;
     width: 224px;
     height: 178px;
     border-radius: 9px;
     overflow:hidden;
     position:relative;
     top:0px;
     left:0px;
     cursor:pointer;
      &:hover .info_boxHover{
       top:0px;
     }
     .itemBox_bg{
       position: absolute;
       top:0px;
       left:0px;
       width: 224px;
       height: 178px;
       background:url('/assets/item_bg.png');
       display: block;
       background-size:100% 100%;
     }
     >img{
       width: 224px;
       height: 178px;
     
     }
     >p{
         position:absolute;
         bottom:9px;
         left:9px;
         line-height:21px;
         color:white;
     }
     .info_boxHover{
         position:absolute;
         top:178px;
         left:0px;
         width: 224px;
         height: 178px;
         padding-left:11px;
         box-sizing: border-box;
         transition:all .5s ease;
         background:rgba(0,0,0,0.7);
         p{
           color:white;
           width: 179px;
           font-size: 14px;
           line-height:19px;
           padding-top:90px;
         }
         strong,em{
           display:block;
           font-size: 12px;
           color:#8a8e8e;
           padding-top:5px;
         }
         span{
           position:absolute;
           bottom:10px;
           right:10px;
           color:white;
           font-size:26px;
         }
    
     }
     .restartTop{
       top:0px;
     }
   }
 }

</style>