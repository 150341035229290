import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    userName: '',
  },
  mutations: {
    GET_COOKIE(state, str) {
      var strArr;
      var strRtn = "";
      strArr = str.split("a");
      for (var i = strArr.length - 1; i >= 0; i--) {
        strRtn += String.fromCharCode(eval(strArr[i]))
      }
      state.userName = strRtn.split("*@")[0]
    },
    JUMP_PLAY(state, par) {
      par.vue.$router.push({
        path: '/videoPlay',
        query: {
          al: par.alid,
          videoId: par.videoId
        }
      })
    },
    LOGIN_INFO(state, parm) {
      if (state.userName == '') {
        parm.vue.$message({
          'message': '请登录'
        })
        return false
      }
    },
    GET_XIU(state, par) {
      let _this = this
      // debugger
      
      axios.defaults.withCredentials = false
      axios.get(`${process.env.VUE_APP_API_xiu_URL}/query/getAvatar?usernames=${encodeURI(par.name)}`).then(response => {
        if (response.data.result == 1) {
          let data = response.data
          for (let i in par.dom) {
            var arr = document.getElementsByClassName(par.dom[i] + 'xiu')
            var s2 = decodeURIComponent(data.content[i].avatarValue);
            var Sex = data.content[0].male;
            Sex = Sex == 1 ? "F" : 'M'
            arr = Array.from(arr)
            arr.forEach((item,index) => {
              par.vue.BeautyContest(s2, item, data.content[i].male, '150', '190');
            })
          }
        } else {

        }
      })
    }
  },
  actions: {
    LOGIN_INFO({
      commit
    }, parm) {
      if (parm.userName == '') {
        parm.vue.$message({
          'message': '请登录'
        })
        return false
      } else {
        return true
      }
    }
  },
  modules: {
  }
})
